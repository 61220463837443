import React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import About from '../components/About/About'
import CallToAction from '../components/CallToAction/CallToAction'

const AboutPage = () => {
  return (
    <>
    <Seo title="About Katia" />
    <Layout>
      <About />
      <CallToAction header="Work with me" text="Work with me" to="/contact" />
    </Layout>
    </>
  )
}

export default AboutPage
