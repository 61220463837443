import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

//We need to add a query to get katia.jpg and pass it to the GatsbyImage component

const AboutWrapper = styled.div`
  text-align: left;
  margin: 160px 20px 80px 20px;

  @media screen and (min-width: 1080px) {
    margin: 80px auto 80px auto;
  }
`

const Grid = styled.div`
  margin: 0 auto;
  max-width: 1080px;
`

const GatsbyImageStyled = styled(GatsbyImage)`
    width: 100%;
    border-radius: 4px;
`

const ImageWrapper = styled.div`
    float: none;
    background-color: var(--darker-grey-transparent);
    border-radius: 4px;

    @media screen and (min-width: 1080px) {
        float: right;
    }
`

const ImageLabel = styled.p`
    padding: 0 20px 10px 20px;
    font-size: 1rem;
`


const About = () => {

   const imageData = useStaticQuery(graphql`
   {
     exampleImage: file(extension: {eq: "jpg"}, name: {eq: "katia"}) {
       childImageSharp {
         gatsbyImageData
       }
     }
   }`) 


   const mainImage = imageData.exampleImage.childImageSharp.gatsbyImageData

  return (
    <AboutWrapper>
      <h1>About Katia Ehlert</h1>
      <Grid>
        <div>
          <h2>Experienced Makeup Artist and Hair</h2>
          <p>
            Katia is a Barcelona city-based makeup artist with a background in
            client relationship management and customer service. With more than
            <strong> 14 years experience</strong> Katia has also gained skills
            working with hair and nails.
          </p>

          <ImageWrapper>
            <GatsbyImageStyled
                image={mainImage}
                alt="Katia working on Mago Pop for Netflix"
                placeholder="blurred"
                layout="fluid"
            />
            <ImageLabel>Katia working on the set of Netflix's "Magic for Humans" with Mago Pop.</ImageLabel>
          </ImageWrapper>
          <p>
            Katia owns and runs her own beauty studio in Barcelona and a new online
            startup.
          </p>
          <p>
            Her beauty makeup is clean, fresh and ideal for fashion and or
            commercial work. Her creative look often incorporates bright colors
            and original design to create high-impact works of art.
          </p>
          <p>
            Katia loves to work on fiction projects enjoying both the creative
            process of styling new characters looks as well as their execution.
          </p>
          <p>
            She loves being on set with the rest of the team and enjoys keeping
            cinematic continuity in check. She is always looking for new work
            and adventures.
          </p>
          <p>
            <strong>Will it be with you?</strong>
          </p>
        </div>
      </Grid>
    </AboutWrapper>
  )
}

export default About
