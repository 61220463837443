import React from "react"
import styled from "styled-components"
import Button from '../ui/Button'

const CallToActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    h2 {
        margin-top: 0;
        margin-bottom: 0;
        color: white;
    }
    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`

const CallToAction = ({header, text, to}) => {
    return (
        <CallToActionContainer>
            <h2>{header}</h2>
            <Button to={to} dark="false">{text}</Button>
        </CallToActionContainer>
    )
}

export default CallToAction
