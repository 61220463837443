import React from 'react'
import styled from 'styled-components'
import { ChevronRight } from 'react-feather'
import { Link } from 'gatsby'

const StyledLinkButton = styled(Link)`
  font-family: 'Quasimoda', Arial, Helvetica, sans-serif;
  font-size: 1em;
  border-radius: 2px;
  color: white;
  border: 2px solid white;
  background-color: transparent;
  margin-left: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  transition: all 0.3s;
  padding: 14px 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1080px) {
    font-size: 1.21em;
    margin-left: 20px;
    padding: 20px 40px;
    margin-top: 0;
  }
  &:focus,
  &:hover {
    cursor: pointer;
    border: 2px solid var(--lipstick);
    color: var(--lipstick);
    padding-left: 30px;
    padding-right: 50px;
  }

  svg {
    transform: translate(-30px, 0);
    position: absolute;
    opacity: 0;
    transition: all 0.3s;
    align-self: flex-end;
  }

  &:focus svg,
  &:hover svg {
    opacity: 1;
    transform: translate(30px, 0);
    transition: all 0.3s;
  }
`

const StyledLinkButtonDark = styled(StyledLinkButton)`
  color: var(--lipstick);
  border: 2px solid var(--lipstick);
  background-color: transparent;

  &:focus,
  &:hover {
    border: 2px solid var(--black);
    color: var(--black);
  }
`

const Button = ({ children, to, dark }) => {
  return (
    <>
      {dark === 'false' ? (
        <StyledLinkButton to={to}>
          <div>{children}</div>
          <ChevronRight />
        </StyledLinkButton>
      ) : (
        <StyledLinkButtonDark to={to}>
          <div>{children}</div>
          <ChevronRight />
        </StyledLinkButtonDark>
      )}
    </>
  )
}

export default Button
